import React, {useState} from 'react'
import './Card.css'
import Navbar from "../Navbar/Navbar";
import Home from "../Home/Home";
import Transactions from "../Transactions/Transactions";
import Receive from "../Receive/Receive";
import {useCardholder} from "../../context/CardholderContext";

const Card = () => {
    const [active, setActive] = useState('initial')

    const {cardholder, loading} = useCardholder()

    if(loading.cardholder)
        return null;

    return (
            <div className='flex-container column'>
                <div id={'card'} className={'card ' + active + '-card'}>
                    <Navbar setActive={setActive}/>
                    <div className="balance">
                        <div>Balance</div>
                        <div>{cardholder.balance} {cardholder.currency}</div>
                    </div>
                    {(() => {
                        switch (active) {
                            case 'home':
                                return <Home/>
                            case 'transactions':
                                return <Transactions/>
                            case 'receive':
                                return <Receive/>
                            default:
                                return <Home/>
                        }})()}
                    <div className="logo-container">
                        <div className="zeniq-logo">
                            <img src={require("../../assets/logo-long.png")} alt=""/>
                        </div>
                        <div className="maestro-logo">
                            <img src={require("../../assets/mastercard.svg").default} alt=""/>
                        </div>
                    </div>
                    <div className="shadow"/>
                </div>
            </div>
    )
}

export default Card