import {createContext, useContext, useEffect, useState} from "react";
import moment from "moment";

export const LoginContext = createContext({});

export const useLogin = () => {
    return useContext(LoginContext)
}

export const LoginContextProvider = ({children}) => {

    const [login, setLogin] = useState(false)
    const [customerId, setCustomerId] = useState(false)
    const [token, setToken] = useState('')

    useEffect(() => {
        let token = localStorage.getItem('token')
        if (token) {
            token = JSON.parse(token)
            if (!moment().isAfter(token.time)){
                // console.log('try login')
                setToken(token.token)
                setLogin(true)
                setCustomerId(token.id)
            }
        }
    }, [])

    const loginFun = (id, new_token) => {
        localStorage.setItem('token', JSON.stringify({
            token: new_token,
            time: moment().add(30, 'minutes'),
            id: id
        }))
        setToken(new_token)
        setLogin(true)
        setCustomerId(id)
    }

    return (
        <LoginContext.Provider value={{
            login,
            customerId,
            loginFun,
            token
        }}>
            {children}
        </LoginContext.Provider>
    )
}