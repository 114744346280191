import React, {useState} from "react";
import {activate} from "../../handlers/apiHandler";
import {log_cards, validate_register} from "../../handlers/authHandler";
import {register_disabled} from "../../utils/constants/dev";

const RegisterForm = React.forwardRef(({wiggle, extend, mobile, setSlideOut, setMobile}, ref) => {
    const [error, setError] = useState()

    const [customerCode, setCustomerCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState();

    async function onSuccessValidateRegister() {
        await activate(customerCode, phoneNumber, onSuccessRegister, onFailedRegister)
    }

    async function onSuccessRegister(card_data) {
        await log_cards(card_data);
        setSlideOut(true);
        setTimeout(() => {
            // localStorage.setItem('logged_in', customerCode);
            // window.location.href = '/';
        }, 2000)
    }

    async function onFailedRegister(error) {
        setError(error);
    }

    async function onValidateRegister() {
        await validate_register(customerCode, phoneNumber, onSuccessValidateRegister, onFailedRegister);
    }

    if(register_disabled) {
        return <></>
    }

    return (
        <div ref={ref}
             className={"form-container register " + (wiggle.register ? ' wiggle-right ' : '') +
                 (extend ? extend + '-form ' : '') + (mobile === 'register' ? 'mobile-show' : 'mobile-hide')}>
            <div className="form-content">
                <div className="label register">Register</div>
                <div className="logo-container">
                    <div className="zeniq-logo">
                        <img src={require("../../assets/logo-long.png")} alt=""/>
                    </div>
                    <div className="maestro-logo">
                        <img src={require("../../assets/mastercard.svg").default} alt=""/>
                    </div>
                </div>
                <div className={"form-information " + (error ? 'red' : '')}>
                    {
                        error ?
                            error :
                            'Please enter your Customer ID and your phone number to register your card!'
                    }
                </div>
                <div className="input-container">
                    <input type='text' className="input" value={customerCode} onInput={e => setCustomerCode(e.target.value)}
                           onKeyPress={async e => {
                               if (e.key === 'Enter') {
                                   await onValidateRegister()
                               }
                           }}/>
                    <div className="image head"/>
                </div>
                <div className="input-container">
                    <input type='text' className="input"
                           value={phoneNumber}
                           onInput={e => setPhoneNumber(e.target.value)}
                           onKeyPress={async e => {
                               if (e.key === 'Enter') {
                                   await onValidateRegister()
                               }
                           }}/>
                    <div className="image phone"/>
                </div>
                <div className="login-button" onClick={async () => {
                    await onValidateRegister()
                }}>Register
                </div>
                <div className="activate" onClick={() => {
                    setMobile('login')
                }}>I already have an account</div>
            </div>
        </div>
    )
})

export default RegisterForm