import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import {LoginContextProvider} from "./context/LoginContext";
import {CardholderContextProvider} from "./context/CardholderContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <LoginContextProvider>
          <CardholderContextProvider>
              <App />
          </CardholderContextProvider>
      </LoginContextProvider>
  </React.StrictMode>
);

