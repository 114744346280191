import React from "react";
import './Home.scss'
import {useCardholder} from "../../context/CardholderContext";
import {useLogin} from "../../context/LoginContext";

const Home = () => {
    const {customerId} = useLogin()
    const {cardholder} = useCardholder()
    return (
        <div className="information">
            <div className="firstname">
                <div>First Name</div>
                <div>{cardholder?.firstname}</div>
            </div>
            <div className="lastname">
                <div>Last Name</div>
                <div>{cardholder?.lastname}</div>
            </div>
            <div className="card-number">
                <div>Card Number</div>
                <div>{cardholder?.card_number}</div>
            </div>
            <div className="card-number">
                <div>Customer id</div>
                <div>{customerId}</div>
            </div>
            <div className="expiration-date">
                <div>Expiration Date</div>
                <div>{cardholder?.expires_at}</div>
            </div>
        </div>
    )
}

export default Home