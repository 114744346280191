import './App.css';
import React from 'react'
import LogIn from "../components/LogIn/LogIn";
import Card from "../components/Card/Card";
import {useLogin} from "../context/LoginContext";

function App() {

    const {login} = useLogin()


    return (
        <div className="App">
            <div className="background"/>
                {
                    login ? <Card/> : <LogIn/>
                }
        </div>
    );
}

export default App;
