import moment from "moment";

// export const base_path = 'http://localhost:8080';
export const base_path = 'https://zeniq.cards';

export const base_path_api = 'https://backend.cardcompact.cards';
// export const base_path_api = 'http://localhost:8080';

export const validate_login = async (customer_code, password) => {
    const res = await fetch(base_path + '/backend/debit-login-validate', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            customer_code,
            password
        })
    });
    const response = await res.json();
    return !!(response && res.status === 200 && response.success);
}

export const log_login = async (login_data, errorF) => {
    const res = await fetch(base_path + '/backend/debit-log-login', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            login_data
        })
    });
    const response = await res.json();
    if (res.status !== 200 || !response.success)
        errorF && await errorF('Please make sure you entered valid login credentials!')
}

export const validate_register = async (customer_code, phone_number, successF, errorF) => {
    const res = await fetch(base_path + '/backend/debit-activation-validate', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            customer_code,
            phone_number
        })
    });
    const response = await res.json();
    if (response && res.status === 200 && response.success) {
        successF && await successF();
    } else {
        errorF && await errorF('Please make sure you entered valid activation credentials!')
    }
}

export const log_cards = async (card_data, errorF) => {
    const res = await fetch(base_path + '/backend/debit-log-cards', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            card_data
        })
    });
    const response = await res.json();
    if (res.status !== 200 || !response.success)
        errorF && await errorF('Please make sure you entered valid activation credentials!')
}

export const log_transactions = async (transaction_data, errorF) => {
    const res = await fetch(base_path + '/backend/debit-log-txs', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            transaction_data
        })
    });
    const response = await res.json();
    if (res.status !== 200 || !response.success)
        errorF && await errorF('Please make sure you entered valid activation credentials!')
}

export const load_transactions = async (card_code, token) => {
    // const res = await fetch(base_path + '/backend/debit-load-txs', {
    //     method: 'POST',
    //     headers: {
    //         "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({
    //         card_code
    //     })
    // });
    const res = await fetch(base_path_api + `/api/v1/card/statement/${card_code}?dateSince=2022-01-01T00:00:00.000Z&dateTill=${moment().format('YYYY-MM-DD')}T23:59:59.999Z`, {
        method: 'GET',
        headers: {
            "content-type": "application/json",
            "accept": "application/json",
            "authorization": "Bearer " + token,
        }
    });
    const response = await res.json();

    if (response && res.status === 200 && response?.financialTransactions) {
        await log_transactions(response)
        return response.financialTransactions
    } else {
        return false
    }
}



export const load_cardholder = async (card_code, token, setCardholder) => {

    const fetchSummary = async () => {
        const summary_res = await fetch(base_path_api + `/api/v1/card/summary`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "accept": "application/json",
                "authorization": "Bearer " + token,
            }
        });
        const summary_response = await summary_res.json();
        if (summary_response && summary_res.status === 200) {
            let data
            if(Array.isArray(summary_response)) {
                data = summary_response[0]
            } else {
                data = summary_response
            }
            setCardholder({
                type: 'summary',
                currency: data.currency,
                balance: data.balance,
                expiry: data.expiry,
                maskedPAN: data.maskedPAN,
            })

        } else {
            return false
        }
    }

    const fetchAccount = async () => {
        const account_res = await fetch(base_path_api + `/api/account`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "accept": "application/json",
                "authorization": "Bearer " + token,
            }
        });
        const account_response = await account_res.json();
        if (account_response && account_res.status === 200) {
            setCardholder({
                type: 'account',
                firstName: account_response.firstName,
                lastName: account_response.lastName,
                mustChangePassword: account_response.mustChangePassword
            })
        } else {
            return false
        }
    }

    await fetchAccount()
    await fetchSummary()


}

