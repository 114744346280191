import React from "react";
import './Transactions.scss'
import {useCardholder} from "../../context/CardholderContext";

const Transactions = () => {

    const {cardholder, transactions, loading} = useCardholder()

    if(loading.transactions)
        return null;

    return (
        <div className="transactions">
            {
                transactions.map((transaction, index) => {
                    return(
                        <div className="transaction-container" key={transaction.id + "_" + index}>
                            <div className="date">{transaction.transactionDate}</div>
                            <div className="transaction-information">
                                <div className={"description "}>{transaction.description}</div>
                                <div className={"amount " + (transaction.debit ? 'red' : 'green')}>{transaction.amount}{cardholder.currency === 'EUR' ? '€' : cardholder.currency}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Transactions