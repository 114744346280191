import React, {useState} from "react";
import {validate_login} from "../../handlers/authHandler";
import {login} from "../../handlers/apiHandler";
import {useLogin} from "../../context/LoginContext";
import {register_disabled} from "../../utils/constants/dev";

const LoginForm = React.forwardRef(({wiggle, extend, mobile, setSlideOut, setMobile}, ref) => {

    const [error, setError] = useState()
    const [showPassword, setShowPassword] = useState(false)

    const [customerCode, setCustomerCode] = useState('');
    const [password, setPassword] = useState('');

    const {loginFun} = useLogin()

    async function onSuccessLogin(token) {
        setSlideOut(true);
        // await log_login(login_data);
        // const transaction_data = await transactions(customerCode)
        // if(transaction_data) {
        //     await log_transactions(transaction_data);
        // }
        setTimeout(() => {
            loginFun(customerCode, token)
        }, 2000)
    }

    async function onValidateLogin() {
        setError('Please make sure you entered valid login credentials!')
        // if(await validate_login(customerCode, password)) {
        //     const token = await login(customerCode, password)
        //     if(token) {
        //         setError('Please make sure you entered valid login credentials!')
        //         // await onSuccessLogin(token)
        //     } else {
        //         setError('Please make sure you entered valid login credentials!')
        //     }
        // } else {
        //     setError('Please make sure you entered valid login credentials!')
        // }
    }

    return (
        <div ref={ref}
             className={"form-container login " + (wiggle.login ? ' wiggle-left ' : '') +
                 (extend ? extend + '-form ' : '') + (mobile === 'login' ? 'mobile-show' : 'mobile-hide')}>
            <div className="form-content">
                <div className="label login">Login</div>
                <div className="logo-container">
                    <div className="zeniq-logo">
                        <img src={require("../../assets/logo-long.png")} alt=""/>
                    </div>
                    <div className="maestro-logo">
                        <img src={require("../../assets/mastercard.svg").default} alt=""/>
                    </div>
                </div>
                <div className={"form-information " + (error ? 'red' : '')}>
                    {
                        error ?
                            error :
                            'Please enter your Customer ID and your Password to sign in!'
                    }
                </div>
                <div className="input-container">
                    <input type='text' className="input" value={customerCode} onInput={e => {setCustomerCode(e.target.value)}}
                           onKeyPress={async e => {
                               if (e.key === 'Enter') {
                                   await onValidateLogin();
                               }
                           }}/>
                    <div className="image head"/>
                </div>
                <div className="input-container">
                    <input type={showPassword ? 'text' : 'password'} className="input"
                           value={password}
                           onInput={e => {setPassword(e.target.value)}}
                           onKeyPress={async e => {
                               if (e.key === 'Enter') {
                                   await onValidateLogin();
                               }
                           }}/>
                    <div className="image key"/>
                    <div className={"image eye " + (showPassword ? 'open' : 'closed')} onClick={() => {
                        setShowPassword(!showPassword)
                    }}/>
                </div>
                <div className="login-button" onClick={async () => await onValidateLogin()}>Login</div>
                {
                    !register_disabled &&
                    <div className="activate" onClick={() => {
                        setMobile('register')
                    }}>Activate your card</div>
                }

            </div>
        </div>
    )
})

export default LoginForm