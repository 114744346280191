import React, {useRef, useState} from 'react'
import './LogIn.css'
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import {register_disabled} from "../../utils/constants/dev";

const LogIn = () => {
    const [slideOut, setSlideOut] = useState(false)
    const [mobile, setMobile] = useState('login')
    const register_form = useRef()
    const login_form = useRef()
    const [extend, setExtend] = useState('')
    const [wiggle, setWiggle] = useState({
        login: true,
        register: true
    })

    return (
        <div className="login-container">
            <div className={"right-container " + (extend ? extend : '') + (slideOut ? ' slideOutRight' : '')}>
                <div className={"packaging " + (extend ? extend + '-packaging' : '')}>
                    <div className="zeniq-logo-packaging">
                        <img src={require("../../assets/logo-long.png")} alt=""/>
                    </div>
                    <div className="background-packaging left" onClick={() => {
                        if(extend !== 'left') {
                            setWiggle({
                                login: false,
                                register: false
                            })
                            setExtend('left')
                            setTimeout(() => {
                                login_form.current?.classList.add('extend')
                            }, 200)
                        } else {
                            setWiggle({
                                login: false,
                                register: false
                            })
                            setExtend('right')
                            setTimeout(() => {
                                register_form.current?.classList.add('extend')
                            }, 200)
                        }
                    }}>
                        <div className="hole left" >
                            <div className={extend === 'left' ? "left-arrow image arrow" : ''}/>
                        </div>
                    </div>
                    {
                        !register_disabled &&
                        <div className="background-packaging right" onClick={() => {
                            if(extend !== 'right') {
                                setWiggle({
                                    login: false,
                                    register: false
                                })
                                setExtend('right')
                                setTimeout(() => {
                                    register_form.current?.classList.add('extend')
                                }, 200)
                            } else {
                                setWiggle({
                                    login: false,
                                    register: false
                                })
                                setExtend('left')
                                setTimeout(() => {
                                    login_form.current?.classList.add('extend')
                                }, 200)
                            }
                        }}>
                            <div className="hole right">
                                <div className={extend === 'right' ? "right-arrow image arrow" : ''}/>
                            </div>
                        </div>
                    }
                    <LoginForm ref={login_form} wiggle={wiggle} extend={extend} mobile={mobile} setSlideOut={setSlideOut} setMobile={setMobile}/>
                    <RegisterForm ref={register_form} wiggle={wiggle} extend={extend} mobile={mobile} setSlideOut={setSlideOut} setMobile={setMobile}/>
                </div>
            </div>
        </div>
    );
}

export default LogIn;
