import React from "react";
import './Receive.scss'
import QRCode from 'react-qr-code';

const Receive = () => {
    return (
        <div className="qr-container">
            <QRCode value={'coming soon'}  size={300}/>
        </div>
    )
}

export default Receive