import {createContext, useContext, useEffect, useReducer, useState} from "react";
import {useLogin} from "./LoginContext";
import {load_cardholder, load_transactions} from "../handlers/authHandler";

export const CardholderContext = createContext({});

const initial_cardholder = {
    id: 123,
    password: null,
    firstname: "",
    lastname: "",
    card_number: "",
    expires_at: '',
    balance: 0,
    currency: '',
    mustChangePassword: false
}

const cardholderReducer = (cardholder, action) => {
    switch (action.type) {
        case 'account':
            return {
                ...cardholder,
                firstname: action.firstName,
                lastname: action.lastName,
                mustChangePassword: action.mustChangePassword
            }
        case 'summary':
            return {
                ...cardholder,
                currency: action.currency,
                balance: action.balance,
                expires_at: action.expiry,
                card_number: action.maskedPAN,
            }
        default:
            throw new Error();
    }
}

const loadingReducer = (loading, action) => {
    switch (action.type) {
        case 'cardholder_loaded':
            return {
                ...loading,
                cardholder: false
            }
        case 'transactions_loaded':
            return {
                ...loading,
                transactions: false
            }
        default:
            throw new Error();
    }
}


export const useCardholder = () => {
    return useContext(CardholderContext)
}

export const CardholderContextProvider = ({children}) => {
    const {login, customerId, token} = useLogin()
    const [cardholder, setCardholder] = useReducer(cardholderReducer, initial_cardholder);
    const [loading, setLoading] = useReducer(loadingReducer,{
        cardholder: true,
        transactions: true
    });
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
            const fetchData = async () => {
                await load_cardholder(customerId, token, setCardholder).then(() => {
                    setLoading({ type: 'cardholder_loaded' });
                })
                await load_transactions(customerId, token).then(data => {
                    // console.log('transaction_data: ', data)
                    data && setTransactions(data)
                    setLoading({ type: 'transactions_loaded' });
                })
            }
            login && fetchData()
        }
        , [login]);

    return (
        <CardholderContext.Provider value={{
            cardholder,
            transactions,
            loading
        }}>
            {children}
        </CardholderContext.Provider>
    )
}