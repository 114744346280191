import React from "react";
import './Navbar.scss'

const Navbar = ({setActive}) => {
    return (
        <div className="navbar">
            <div className="home" onClick={() => {
                setActive('home')
            }}>
                <img src={require('../../assets/home.svg').default} alt=""/>
            </div>
            <div className="transaction-icon" onClick={() => {
                setActive('transactions')
            }}>
                <img src={require('../../assets/transaction.svg').default} alt=""/>
            </div>
            <div className="receive" onClick={() => {
                setActive('receive')
            }}>
                <img src={require('../../assets/receive.svg').default} alt=""/>
            </div>
            <div className="logout" onClick={() => {
                document.getElementById('card').classList.add('fly-out')
                setTimeout(() => {
                    localStorage.removeItem('token');
                    window.location.href = '/';
                }, 1200)
            }}>
                <img src={require('../../assets/logout.svg').default} alt=""/>
            </div>
        </div>
    )
}

export default Navbar